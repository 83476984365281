import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';
import { Color, borderRadius, companyRowHeight, mediaQuery } from '../../components/Theme';

export const Section = styled.div<{ flex: boolean; width: number | string | null; right?: boolean; bold?: boolean; pointer?: boolean }>`
  ${({ flex }) =>
    flex &&
    css`
      flex: 1;
    `}

  ${({ width }) =>
    width !== null &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}

  ${({ pointer }) =>
    !!pointer &&
    css`
      cursor: pointer;
    `}

  text-align: ${({ right }) => (right ? 'right' : 'left')};
  user-select: none;

  .MuiTypography-root {
    display: flex;
    justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
    align-items: center;
    font-weight: ${({ bold }) => (bold ? '500' : 'normal')};
  }

  .MuiTypography-body1 {
    > svg,
    .premium-icon {
      &:first-child {
        margin-right: 0.3rem;
      }

      &:last-child {
        margin-left: 0.3rem;
      }
    }
  }

  .MuiTypography-body2 {
    opacity: 0.5;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0.5rem 0 0;

  > span {
    margin-right: 0.25rem;
    opacity: 0;
    color: ${Color.black};
  }

  > span:first-child {
    opacity: 0;
  }

  > span:last-child {
    opacity: 0.5;
  }

  .MuiButtonBase-root {
    &:active,
    &:focus,
    &.active {
      opacity: 1;
    }

    &.liked {
      color: ${Color.green};
    }

    &.disliked {
      color: ${Color.red};
    }
  }
`;

export const Row = styled.div<{ small: boolean }>`
  display: flex;
  height: ${companyRowHeight}px;
  background-color: ${Color.white};

  &:not(:first-child) a {
    margin-top: -2px;
  }

  &:first-child {
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;

    &:after {
      border-radius: ${borderRadius}px ${borderRadius}px 0 0;
    }
  }

  &:last-child {
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

    &:after {
      border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    }

    a {
      border-bottom: none;

      &:after {
        height: 100%;
      }
    }
  }

  &:first-child:last-child {
    border-radius: ${borderRadius}px;
  }

  &[aria-selected='true'] a {
    background-color: ${Color.select};

    &:after {
      border: 2px solid ${Color.primary};
    }
  }

  > a {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
    border-radius: inherit;
    border-bottom: 2px solid ${Color.background};
    outline: none;
    z-index: 1;

    box-sizing: border-box;

    transition: box-shadow 100ms ease;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 2px);
      border: 2px solid transparent;
      border-radius: inherit;
      pointer-events: none;

      box-sizing: border-box;
    }

    &:hover {
      background-color: ${Color.hover};
      box-shadow: 0 0 11px rgba(149, 157, 165, 0.15);
      z-index: 2;

      &:after {
        border: 2px solid ${Color.secondary};
      }

      > button {
        opacity: 1;
      }

      ${Actions} {
        > span {
          opacity: 1;
        }
      }
    }

    &:active,
    &:focus {
      background-color: ${Color.select};

      &:after {
        border: 2px solid ${Color.primary};
      }

      > button {
        opacity: 1;
      }

      ${Actions} {
        > span {
          opacity: 1;
        }
      }
    }

    ${(props) =>
      props.small &&
      css`
        > *:first-child {
          margin: 0.2rem 1rem;
        }

        &:hover {
          box-shadow: none;
        }
      `}
    small {
      display: block;
      margin-top: 0.2rem;
      font-size: 0.8rem;
      color: ${Color.disabled};
    }

    p {
      > svg {
        // margin-bottom: -2px;
        color: ${transparentize(0.5, Color.black)};
      }
    }

    p:first-of-type {
      flex: 1;
    }

    p:nth-of-type(2) {
      flex: 0.5;
      min-width: 60px;
    }

    p:nth-of-type(3) {
      width: 100px;
    }

    p:nth-of-type(4) {
      text-align: right;
      width: 150px;
    }

    hr {
      margin: 0 1rem;
      height: 42px;
      width: 2px;
      border: none;
      background-color: ${Color.background};
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 1.5rem;
  width: 42px;
  height: 42px;
  background-color: ${Color.background};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: ${borderRadius}px;
  overflow: hidden;

  > figure.logo {
    width: 100%;
    height: 100%;
  }

  svg {
    color: ${Color.disabled};
  }

  ${mediaQuery.small(css`
    margin: 1.5rem 0.75rem;
  `)}
`;

export const Button = styled.button`
  display: block;
  align-self: stretch;
  width: 46px;
  background: none;
  border: 2px solid transparent;
  outline: none;
  opacity: 0;
  color: ${Color.black};

  &.star.selected {
    opacity: 1;
    color: ${Color.primary};
  }

  &.thumbUp.selected {
    opacity: 1;
    color: ${Color.green};
  }

  &.thumbDown.selected {
    opacity: 1;
    color: ${Color.red};
  }

  &:active,
  &:focus {
    border-color: ${Color.primary};
    background-color: ${darken(0.05, Color.select)};

    opacity: 1;
  }

  &:hover {
    border-color: transparent;
    background-color: ${darken(0.075, Color.hover)};

    &.star {
      color: ${Color.primary};
    }
  }

  &:first-of-type {
    margin-left: 1rem;
  }

  &:not(:last-of-type) {
    margin-right: -2px;
  }

  @media (max-width: 1000px) {
    opacity: 0.5;
  }
`;
