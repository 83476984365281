import React, { FC, CSSProperties, MouseEvent, useCallback, memo } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import StoreIcon from 'mdi-react/StoreIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { companyUrlParts } from '../../lib/utils';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { Section, Logo, Row, Actions } from './CompanyListStyle';
import Typography from '@material-ui/core/Typography';
import { Color } from '../../components/Theme';
import IconButton from '@material-ui/core/IconButton';
import { WWWSearchItem } from '../../common/documents/WWWSearch';
import { CompanyListRowSkeleton } from './CompanyListRowSkeleton';
import { useCompanyActionsInSearch } from '../company/useCompanyActions';
import { useUser } from '../../context/useUser';
import { formatMinMax } from '../../common/constleUtils';
import { Skeleton } from '../company/CompanyFieldAndHelpers';
import { CompanyListRowSectionProps, companyListRowSections, sectionWidths } from './CompanyListRowSections';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { VastuuGroupIcon } from '../icons/VastuuGroupIcons';
import { CompanyFlag } from '../../common/Constants';
import { PremiumIcon } from '../PremiumIcon';

export const IsInListIndicator = styled.div`
  position: absolute;
  margin: -26px -26px 0 0;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${Color.primary};
`;

const SubsectionList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${Section} {
    display: block;
    flex: 0;
    width: auto;
    margin-right: 1rem;

    .MuiTypography-root {
      justify-content: flex-start;
      text-align: left;
      white-space: nowrap;
    }
  }
`;

export interface CompanyListRowProps {
  id?: string;
  company: WWWSearchItem | null;
  small?: boolean;
  selected?: boolean;
  sections?: typeof companyListRowSections[number][];
  element?: keyof JSX.IntrinsicElements;

  /** Should this row be treated as premium enabled? */
  demo?: boolean;

  renderActions?: (company: WWWSearchItem) => JSX.Element;

  /** Overrides renderActions if given */
  actionsComponent?: FC<WWWSearchItem>;

  style?: CSSProperties;

  onMenu?: (companyID: number, event: MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
}

export const CompanyListRowSection: FC<CompanyListRowSectionProps> = ({
  name,
  title,
  subtitle,
  icon,
  endIcon,
  width,
  right,
  bold,
  style,
  subsections,
  onClick,
}) => {
  let extraData = null;
  if (subsections) {
    extraData = (
      <SubsectionList>
        {subsections.map((props, index) => (
          <CompanyListRowSection key={index} {...props} right={false} width={null} />
        ))}
      </SubsectionList>
    );
  }

  return (
    <Section
      className={classNames({ right }, name)}
      flex={!width}
      width={width ?? null}
      right={!!right}
      bold={bold}
      pointer={!!onClick}
      style={{ ...style }}
      onClick={onClick}
    >
      <Typography>
        {icon ?? null}
        {title ? <span>{title}</span> : null}
        {endIcon ?? null}
      </Typography>
      {!!subtitle && !extraData && subtitle}
      {extraData}
    </Section>
  );
};

// NOTE! This is used both for search page rows and for frontpage "Viimeksi päivitetyt" also
export const CompanyListRow: FC<CompanyListRowProps> = memo(
  ({
    id,
    company,
    small,
    selected,
    sections = companyListRowSections,
    element = null,
    demo,
    renderActions = null,
    actionsComponent: ActionsComponent = null,
    style,
    onMenu,
    onClick,
  }) => {
    const { isLoggedIn } = useUser();
    const { isLiked, isDisliked, isInCustomList, listIDs, renderComboButton } = useCompanyActionsInSearch(company?.companyID);
    const isSmall = useBreakPoint('small');

    if (company === null) {
      return <CompanyListRowSkeleton small={small} />;
    }

    const handleMenuClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (onMenu) onMenu(company.companyID, event);
      },
      [company.companyID, onMenu]
    );

    const renderListRowActions = useCallback(() => {
      if (renderActions) {
        return renderActions({ ...company, isLiked, isDisliked, listIDs });
      }

      const width = isSmall ? 48 : sectionWidths.actions;

      return (
        <Actions style={{ width }} className="actions">
          {!isSmall && renderComboButton()}
          <IconButton aria-label="more options" component="span" disabled={!isLoggedIn} onClick={handleMenuClick}>
            <DotsHorizontalIcon />
            {isInCustomList && <IsInListIndicator />}
          </IconButton>
        </Actions>
      );
    }, [id, isLiked, isDisliked, isInCustomList, isLoggedIn, isSmall]);

    const renderListRowSection = useCallback(
      (section: CompanyListRowSectionProps, index: number) => <CompanyListRowSection key={index} {...section} />,
      []
    );

    const renderListRow = useCallback(
      (company: CompanyListRowProps['company']) => {
        const hasLogo = company.logo && company.logo.path;

        // if established in previous year or later, show month
        const previousYear = new Date().getFullYear() - 1;
        const establishedMonthOrEmpty: string =
          company.dateEstablished && new Date(company.dateEstablished).getFullYear() >= previousYear
            ? format(parseISO(company.dateEstablished), 'M') + '/'
            : '';

        const dateEstablishedString = company.dateEstablished
          ? establishedMonthOrEmpty + format(parseISO(company.dateEstablished), 'yyyy')
          : '';
        const numberOfEmployeesString = formatMinMax(company.numberOfEmployees);
        const turnoverString = formatMinMax(company.turnover, '€');

        const hasNumberOfEmployees = !!numberOfEmployeesString;
        const hasTurnover = !!turnoverString;

        let logoStyleProp = {};
        if (hasLogo) {
          logoStyleProp = {
            backgroundImage: `url(/api/v1/files/public/42x42M-${company.logo.path})`,
            backgroundColor: 'white',
            border: 'none',
          };
        }

        const companyFlagIcons = ((sections.includes('flags') && company.flags) || [])
          .filter((f) => f.flagID != CompanyFlag.IS_VASTUUGROUP)
          .map((f, i) => f.value && f.flagID && <VastuuGroupIcon style={{ verticalAlign: '13%' }} key={i} companyFlagID={f.flagID} />);

        const businessIDSection = {
          name: 'businessID',
          subtitle: (
            <>
              <Typography variant="body2" component="span" style={{ display: 'inline' }}>
                {company.businessID}
              </Typography>
              {companyFlagIcons}
            </>
          ),
        };

        const locationSection = {
          name: 'domicile',
          title: company.domicile?.cityName,
          subtitle: <Typography variant="body2">{company.domicile?.provinceName}</Typography>,
          width: sectionWidths.location,
        };

        const dateEstablished = {
          name: 'dateEstablished',
          title: dateEstablishedString,
          width: sectionWidths.year,
          right: true,
        };

        const employeesSection = {
          name: 'numberOfEmployees',
          title: numberOfEmployeesString,
          endIcon: hasNumberOfEmployees && <AccountMultipleIcon size={16} />,
          width: sectionWidths.employees,
          right: true,
        };

        const turnoverSection = {
          name: 'turnover',
          title: hasTurnover ? formatMinMax(company.turnover) + '€' : '',
          // subtitle: '2019',
          icon: hasTurnover && <TrendingUpIcon size={16} />,
          width: sectionWidths.turnover,
          right: true,
        };

        const nameSubsections = [];
        if (!demo) {
          if (!sections.includes('turnover')) {
            nameSubsections.push(turnoverSection);
          }
          if (nameSubsections.length) {
            nameSubsections.unshift(businessIDSection);
          }
        }

        // Enforce that all companyListRowSections exept for "actions" is defined here
        const sectionProps: Record<Exclude<typeof companyListRowSections[number], 'actions' | 'flags'>, CompanyListRowSectionProps> = {
          name: {
            name: 'name',
            title: company.name,
            subtitle: (
              <>
                <Typography variant="body2" component="span" style={{ display: 'inline' }}>
                  {company.businessID}
                </Typography>
                {companyFlagIcons}
              </>
            ),
            subsections: nameSubsections.length ? nameSubsections : undefined,
          },
          location: locationSection,
          year: dateEstablished,
          employees: employeesSection,
          turnover: turnoverSection,
        };

        // Pick visible sections based on the "sections" prop
        const visibleSections = sections
          .filter((section) => section !== 'actions' && section !== 'flags')
          .map((section) => sectionProps[section]);

        return (
          <>
            {/* Logo */}
            <Logo style={logoStyleProp}>{!hasLogo && <StoreIcon />}</Logo>

            {/* Sections */}
            {visibleSections.map(renderListRowSection)}

            {/* Actions */}
            {!ActionsComponent && sections.includes('actions') && renderListRowActions()}
            {ActionsComponent && sections.includes('actions') && <ActionsComponent {...company} />}
          </>
        );
      },
      [id, small, isLiked, isDisliked, isInCustomList, sections, isLoggedIn, isSmall]
    );

    return (
      <Row id={id} as={element} style={style} role="option" aria-selected={selected || false} small={small} onClick={onClick}>
        <Link
          href={{
            pathname: '/company/[hash]/[slug]',
            query: companyUrlParts(company.url),
          }}
          passHref
        >
          <a>{renderListRow(company)}</a>
        </Link>
      </Row>
    );
  },

  /**
   * Prevents unnecessary updates on company row component.
   * This has a huge performance boost on the search page.
   * @returns Is component equal to last props
   */
  (a, b) => {
    if (a.company?.companyID !== b.company?.companyID) return false;
    if (a.small !== b.small) return false;
    if (a.selected !== b.selected) return false;
    if (a.sections !== b.sections) return false;

    return true;
  }
);
