import { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { CompanyFlag } from '../../common/Constants';

const LetterIcon = styled.span<{ bgColor: string }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 3px 4px;
  font-size: 10px;
  font-weight: normal;
  line-height: 130%;
  margin-left: 10px;
  margin-right: -2px;
  text-align: center;
  color: #fff !important;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
`;

const LetterIconComponent: FC<{ label: string; bgColor: string; tooltip: string }> = ({ label, tooltip, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <LetterIcon {...props}>{label}</LetterIcon>
    </Tooltip>
  );
};

const iconSettings = {
  [CompanyFlag.IS_TAITOREKISTERI]: { label: 'TR', bgColor: '#a78efd', tooltip: 'Yritys kuuluu Taitorekisteriin' },
  [CompanyFlag.IS_VALTTIKORTTI]: { label: 'VK', bgColor: '#ef602d', tooltip: 'Yrityksellä on käytössä Valttikortti -palvelu' },
  [CompanyFlag.IS_LUOTETTAVAKUMPPANI]: {
    label: 'LK',
    bgColor: '#0f9a60',
    tooltip: 'Yritykseltä on saatavissa Luotettava Kumppani -raportti',
  },
};

export const VastuuGroupIcon = ({ companyFlagID, ...rest }: { companyFlagID: CompanyFlag; style?: any }) => (
  <LetterIconComponent
    label={iconSettings[companyFlagID].label}
    bgColor={iconSettings[companyFlagID].bgColor}
    tooltip={iconSettings[companyFlagID].tooltip}
    {...rest}
  />
);
