import { FC, useCallback, memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import DiamondIcon from 'mdi-react/DiamondIcon';
import { Section, Logo, Row, Actions } from './CompanyListStyle';
import { CompanyListRowSectionProps, companyListRowSections, sectionWidths, useSearchSections } from './CompanyListRowSections';
import { Color } from '../Theme';

const CompanyListRowSkeletonSection: FC<CompanyListRowSectionProps & { premium: boolean }> = memo(({ subtitle, width, right, premium }) => (
  <Section flex={!width} width={width ?? null} right={!!right}>
    <Typography>
      <Skeleton animation={premium ? false : 'wave'} width="80%" />
    </Typography>
    {subtitle !== undefined && (
      <Typography variant="body2">
        <Skeleton animation={premium ? false : 'wave'} width="30%" />
      </Typography>
    )}
  </Section>
));

export interface CompanyListRowProps {
  sections?: typeof companyListRowSections[number][];
  small?: boolean;
  premium?: boolean;
}

export const CompanyListRowSkeleton: FC<CompanyListRowProps> = memo(({ sections = null, small, premium }) => {
  const searchSections = useSearchSections();

  const visibleSection = sections ?? searchSections;

  const renderListRowActions = useCallback(
    () => (
      <Actions style={{ width: sectionWidths.actions }}>
        <Skeleton
          className="icon"
          animation={premium ? false : 'wave'}
          width={24}
          height={24}
          style={{ transform: 'none', borderRadius: '50%', margin: 0, marginRight: '1rem' }}
        />
      </Actions>
    ),
    [premium]
  );

  const renderListRowSection = useCallback(
    (section: CompanyListRowSectionProps, index) => <CompanyListRowSkeletonSection key={index} {...section} premium={premium} />,
    [premium]
  );

  const renderListRow = useCallback(() => {
    const sections: CompanyListRowSectionProps[] = [
      // Name
      { name: 'name', title: '', subtitle: '' },

      // Location
      { name: 'location', title: '', subtitle: '', width: sectionWidths.location },

      // Year
      { name: 'year', title: '', width: sectionWidths.year, right: true },

      // Employees
      { name: 'employees', title: '', width: sectionWidths.employees, right: true },

      // Turnover
      {
        name: 'turnover',
        title: '',
        subtitle: '',
        width: sectionWidths.turnover,
        right: true,
      },
    ].filter(({ name }) => visibleSection.includes(name as any));

    return (
      <>
        {/* Logo */}
        <Logo>
          {premium ? (
            <DiamondIcon color={Color.primary} size={16} />
          ) : (
            <Skeleton className="icon" animation="wave" width={42} height={42} style={{ transform: 'none', borderRadius: 4, margin: 0 }} />
          )}
        </Logo>

        {/* Sections */}
        {sections.map(renderListRowSection)}

        {/* Actions */}
        {visibleSection.includes('actions') && renderListRowActions()}
      </>
    );
  }, [small, premium, visibleSection]);

  return (
    <Row small={small}>
      <a>{renderListRow()}</a>
    </Row>
  );
});
