import React, { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import shallow from 'zustand/shallow';
import { useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from 'mdi-react/ThumbUpIcon';
import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon';
import ThumbsUpOutlineIcon from 'mdi-react/ThumbsUpOutlineIcon';
import AlertDecagramOutlineIcon from 'mdi-react/AlertDecagramOutlineIcon';
import { ListType } from '../../common/Constants';
import type { WWWCompanyDocument } from '../../common/documents/WWWCompanyDocument';
import { useUser } from '../../context/useUser';
import { listsState } from '../../context/listsStore';
import { useEditSearchItem } from '../../context/searchStore';
import { useScopedState } from '../../lib/state';
import { Color } from '../Theme';

export interface CompanyActionsData {
  isLiked: boolean;
  isDisliked: boolean;
  listIDs: any[] | number[];
}

export type CompanyActions = ReturnType<typeof useCompanyActions>;

type CompanyActionCacheState = {
  [companyID: string]: CompanyActionsData;
};

/**
 * Zustand store to handle single company action cache.
 * This will allow instant updates for likes, dislikes & lists.
 */
const useCompanyActionCacheState = create(combine({} as CompanyActionCacheState, (set) => ({ set })));

/**
 * Central place for everything to do with company actions
 * These include:
 * - Liking
 * - Disliking
 * - Interaction with lists
 */
const useCompanyActions = (companyID: number, actionData: CompanyActionsData, doEdit: (data: Partial<CompanyActionsData>) => void) => {
  const { formatMessage } = useIntl();
  const { isLoggedIn } = useUser();
  const { lists, doAddItem, doRemoveItem } = useScopedState(listsState, ['lists']);
  const isLoadingRef = useRef<boolean>(false);

  const likedListID = lists.find((list) => list.listTypeID === ListType.LIKED)?.listID || null;
  const dislikedListID = lists.find((list) => list.listTypeID === ListType.DISLIKED)?.listID || null;

  const { isLiked, isDisliked, listIDs } = actionData;

  const likeText = useMemo(() => formatMessage({ id: 'company.actions.like', defaultMessage: 'Lisää tykkäyksiin' }), []);
  const removeLikeText = useMemo(() => formatMessage({ id: 'company.actions.remove-like', defaultMessage: 'Poista tykätyistä' }), []);
  const dislikeText = useMemo(() => formatMessage({ id: 'company.actions.dislike', defaultMessage: 'Lisää vältettyihin' }), []);
  const removeDislikeText = useMemo(
    () => formatMessage({ id: 'company.actions.remove-dislike', defaultMessage: 'Poista vältetyistä' }),
    []
  );

  /** Helper to allow other components to easily access translations */
  const dictionary = useMemo(
    () => ({
      likeText,
      removeLikeText,
      dislikeText,
      removeDislikeText,
    }),
    []
  );

  /** Helper to check if company is in user or company lists */
  const isInCustomList = (listIDs || []).length > 0;

  /** Helper to return a given status, and set loading state to false */
  const resolve = useCallback((status: string) => {
    isLoadingRef.current = false;
    return status;
  }, []);

  const handleThumbUpClick = useCallback(
    async (event: MouseEvent<HTMLElement> = null) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      // If some action is already loading, skip
      if (isLoadingRef.current) return 'ok';
      isLoadingRef.current = true;

      if (!isLiked) {
        doEdit({ isLiked: true });

        const { status } = await doAddItem({ listID: likedListID, companyID });
        if (status !== 'ok') {
          doEdit({ isLiked: false });
          console.error(`Failed to like company (${companyID}) with status "${status}"`);
        } else {
          doEdit({ isDisliked: false });
        }

        return resolve(status);
      } else {
        doEdit({ isLiked: false });

        const { status } = await doRemoveItem({
          listID: likedListID,
          companyID,
          pendingOverride: true,
        });
        if (status !== 'ok') {
          doEdit({ isLiked: true });
          console.error(`Failed to remove like from company (${companyID}) with status "${status}"`);
        }

        return resolve(status);
      }
    },
    [companyID, likedListID, isLiked, doEdit]
  );

  const handleThumbDownClick = useCallback(
    async (event: MouseEvent<HTMLElement> = null) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      // If some action is already loading, skip
      if (isLoadingRef.current) return 'ok';
      isLoadingRef.current = true;

      if (!isDisliked) {
        doEdit({ isDisliked: true });

        const { status } = await doAddItem({ listID: dislikedListID, companyID });
        if (status !== 'ok') {
          doEdit({ isDisliked: false });
          console.error(`Failed to dislike company (${companyID}) with status "${status}"`);
        } else {
          doEdit({ isLiked: false });
        }

        return resolve(status);
      } else {
        doEdit({ isDisliked: false });

        const { status } = await doRemoveItem({ listID: dislikedListID, companyID, pendingOverride: true });
        if (status !== 'ok') {
          doEdit({ isDisliked: true });
          console.error(`Failed to remove dislike from company (${companyID}) with status "${status}"`);
        }

        return resolve(status);
      }
    },
    [companyID, dislikedListID, isDisliked, doEdit]
  );

  /**
   * Removes a company from list if it exists, or adds it if it does not
   */
  const handleToggleListClick = useCallback(
    async (listID: number, event: MouseEvent<HTMLButtonElement> = null) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      // If some action is already loading, skip
      if (isLoadingRef.current) return 'ok';
      isLoadingRef.current = true;

      const isInList = listIDs.includes(listID);

      if (!isInList) {
        doEdit({ listIDs: [...listIDs, listID] });

        const { status } = await doAddItem({ listID, companyID });
        if (status !== 'ok') {
          doEdit({ listIDs });
          console.error(`Failed to add company (${companyID}) to list (${listID}) with status "${status}"`);
        }

        return resolve(status);
      } else {
        doEdit({ listIDs: listIDs.filter((id) => id !== listID) });

        const { status } = await doRemoveItem({
          listID,
          companyID,
          pendingOverride: true,
        });
        if (status !== 'ok') {
          doEdit({ listIDs });
          console.error(`Failed to remove company (${companyID}) from list (${listID}) with status "${status}"`);
        }

        return resolve(status);
      }
    },
    [companyID, listIDs, doEdit]
  );

  const renderThumbUpButton = useCallback(
    () => (
      <IconButton
        aria-label={isLiked ? likeText : removeLikeText}
        component="span"
        className={isLiked ? 'active' : ''}
        style={isLiked ? { color: Color.green } : {}}
        disabled={!isLoggedIn}
        onClick={handleThumbUpClick}
      >
        {isLiked ? <ThumbUpIcon /> : <ThumbsUpOutlineIcon />}
      </IconButton>
    ),
    [handleThumbUpClick, isLiked, isLoggedIn]
  );

  const renderThumbDownButton = useCallback(
    () => (
      <IconButton
        aria-label={isDisliked ? dislikeText : removeDislikeText}
        component="span"
        className={isDisliked ? 'active' : ''}
        style={isDisliked ? { color: Color.red } : {}}
        disabled={!isLoggedIn}
        onClick={handleThumbDownClick}
      >
        {isDisliked ? <AlertDecagramIcon /> : <AlertDecagramOutlineIcon />}
      </IconButton>
    ),
    [handleThumbDownClick, isDisliked, isLoggedIn]
  );

  /**
   * Button that shows both liked and disliked state as follows:
   * - Neutral: Hollow like icon
   * - Liked: Green liked icon
   * - Disliked: Red dislike icon
   */
  const renderComboButton = useCallback(
    () => (
      <IconButton
        aria-label={isDisliked ? removeDislikeText : isLiked ? removeLikeText : likeText}
        component="span"
        className={[isLiked || isDisliked ? 'active' : '', isLiked ? 'liked' : '', isDisliked ? 'disliked' : ''].join(' ')}
        color={isLiked ? 'primary' : 'default'}
        disabled={!isLoggedIn}
        onClick={isDisliked ? handleThumbDownClick : handleThumbUpClick}
      >
        {isDisliked ? <AlertDecagramIcon /> : isLiked ? <ThumbUpIcon /> : <ThumbsUpOutlineIcon />}
      </IconButton>
    ),
    [handleThumbUpClick, handleThumbDownClick, isLiked, isDisliked, isLoggedIn]
  );

  return {
    isLiked,
    isDisliked,
    isInCustomList,
    listIDs,
    handleThumbUpClick,
    handleThumbDownClick,
    handleToggleListClick,
    renderThumbUpButton,
    renderThumbDownButton,
    renderComboButton,
    dictionary,
  };
};

/**
 * Handle company actions in search results.
 * Updates the search result list and cache accordingly.
 */
export const useCompanyActionsInSearch = (companyID: number) => {
  const { doEdit, ...actionData } = useEditSearchItem(companyID);
  return useCompanyActions(companyID, actionData, doEdit);
};

/**
 * Handle company actions in company page.
 * Updates the company data accordingly.
 */
export const useCompanyActionsInPage = (company: WWWCompanyDocument) => {
  const { companyID, isLiked, isDisliked, listIDs } = company;
  const companyIDString = `${companyID}`;

  // Also edit search data to update (like, dislike, list) status when going back to search page / refreshing search etc.
  const { doEdit: doSearchEdit } = useEditSearchItem(companyID, true);

  const { set, actionCache } = useCompanyActionCacheState(
    ({ set, ...state }) => ({ set, actionCache: state[companyIDString] }),
    (a, b) => shallow(a, b)
  );

  // Cache of initial company state + new like, dislike & list changes
  const companyActionCache = actionCache || { companyID, isLiked, isDisliked, listIDs };

  // Update action data if company data changes
  useEffect(() => {
    set({ [companyIDString]: { ...companyActionCache, isLiked, isDisliked, listIDs } });
  }, [companyID, isLiked, isDisliked, listIDs]);

  const doEdit = useCallback(
    (data: Partial<CompanyActionsData>) => {
      const currentState = useCompanyActionCacheState.getState()[companyIDString] || companyActionCache;
      set({ [companyIDString]: { ...currentState, ...data } });
      doSearchEdit(data);
    },
    [companyIDString]
  );

  return useCompanyActions(companyID, companyActionCache, doEdit);
};
